import store from "@/store"
import AddSchedule from "@/components/AddSchedule";
import EditSchedulePage from "@/components/EditSchedule";
let sdl = {};
let vm;
let method = "MySchedule";
let listMethods = {
  addSchedule() {

  },
  AddSchedule() {
    this.openeditbox = true;
    this.addScheduletype = 'insert';
  },
  closefunc() {
    this.openeditbox = false;
    listMethods.GetSchedule(this.ScheduleMethod);
  },
  callfunc() {

  },
  GetSchedule(method) {
    const result = store.dispatch("schedule/getSchedule", method);
  },
  EditScheduleInfo(data) {
    console.log("edit")
    this.Editdata.show = true;
    this.Editdata.data = data;
  },
  reback(data) {
    this.GetSchedule(this.ScheduleMethod);
  },
  ChangeMedod(method) {
    this.ScheduleMethod = method;
    this.GetSchedule(this.ScheduleMethod);
  },
  deleteMySchedule(data) {
    if (data) {
      this.GetSchedule(this.ScheduleMethod);
    }
  },
  login() {
    this.$store.dispatch("user/openLogin", true);
  }
};
let nameComputed = {
  listdata: {
    get: () => {
      return store.state.schedule.myschedule;
    },
    set: () => {

    }
  },
  member: {
    get() {
      return store.state.user.Login;
    }
  }
};
export default {
  data() {
    this.GetSchedule(method);
    return {
      lang: store.state.Public.lang.schedule,
      boxMinus: true,
      ScheduleMethod: method,
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#bbb'
      },
      Editdata: {
        show: false,
        data: {}
      },
      openeditbox: false,
      addScheduletype: "insert",
      // listdata: store.state.schedule.myschedule
    };
  },
  methods: listMethods,
  computed: nameComputed,
  components: {
    AddSchedule,
    EditSchedulePage
  },
  beforeRouteEnter(to, from, next) {
    next();
  }
};
