<template>
  <div>
    <div class='row no-gutters'>
      <div class="col-12 page" v-if='page==1'>
        <div class="form-group">
          <div>
            {{lang.schedulename}}
          </div>
          <input type="text" class='form-control' v-model='infodata.name'>
          <font-awesome-icon class='icon-left' :icon="['fas', 'map-marked-alt']" />
        </div>

        <div class="row no-gutters">
          <div class='col-2'>
            {{langpublic.country}}
          </div>

          <SelectDrop class='col-10' :checkedlist="infodata.country" :openfunc='OpenClick'
            :btntext='langpublic.selectcountry'>
          </SelectDrop>

          <div class="col-12 mt-1 mb-1">
            <label for="" class='bg-info mr-1 p-1 text-white' v-for='(item,key) in countrylist'>
              {{item.name}}
              <font-awesome-icon :icon="['fas', 'window-close']" class='cur-print'
                @click='deleteitem(key)' />
            </label>
          </div>
        </div>
      </div>

      <div class="col-12 page" v-if='page==2'>
        <div class="form-group">
          <label for="">
            {{lang.scheduleStart}}
          </label>
          <date-picker v-model="infodata.startdate" :first-day-of-week="1" width='100%'
            :placeholder='lang.scheduleSelectDate' :clearable='true' :shortcuts='false'>
          </date-picker>
        </div>
        <div class="form-group">
          <label for="">
            {{lang.scheduleTotalDay}}
          </label>
          <input type="number" class='form-control' v-model='infodata.totalday'>
          <font-awesome-icon class='icon-left' :icon="['fas', 'calendar-day']" />
        </div>
        <div class="form-group">
          <label for="">
            {{lang.scheduleEnd}}
          </label>
          <date-picker :disabled=true v-model="infodata.enddate" :first-day-of-week="1" width='100%'
            :placeholder='lang.scheduleSelectDate' :clearable='true' :shortcuts='false'>
          </date-picker>
        </div>
      </div>

      <div class="col-12 page" v-if='page==3'>
        <div class="form-group">
          <label for="">
            {{lang.scheduleFirst}}
          </label>
          <v-select :placeholder="lang.scheduleSelectFirst" @input='changeFirstday'
            :options="countrylist" label='name' v-model='infodata.FirstArea'>
          </v-select>
          <!-- <font-awesome-icon class='icon-left' :icon="['fas', 'map-marked-alt']" /> -->
        </div>
        <div class="clearfix" v-if='infodata.FirstArea'>
          <v-select :options='arealist' label='name' v-model='FirstTmpselect'
            :placeholder="lang.scheduleFirstQuitSelect"></v-select>
        </div>
        <div class="row" v-for="data in FirstTmepFilter">
          <div class="col-12">
            {{data.name}}
          </div>
          <div class="col-md-4 col-sm-6" v-for="item in data.sub">
            <input type="radio" class="oc" :id='"AREA-"+item.City+"-"+item.area_uid'
              :value="item.area_uid" v-icheckStyle='infodata.FirstLocation'
              :checked='infodata.FirstLocation.indexOf(item.area_uid)>-1'>
            <label :for='"AREA-"+item.City+"-"+item.area_uid'>
              {{item.area_name}}
            </label>
          </div>
        </div>
      </div>
    </div>

    <div class="row clearfix justify-content-center">
      <a href="#0" class="btn btn-secondary rounded-pill col-6 " @click.prevent='pre()'
        v-if='page>1'>{{langresign.pretext}}</a>
      <a href="#0" class="btn mos-bg-rad rounded-pill col-6 " @click.prevent='next()'
        v-if='page<3'>{{langpublic.nextpage}}</a>
      <a href="#0" class="btn mos-bg-rad rounded-pill col-6 " @click.prevent='submit()'
        v-if='page==3'>{{lang.scheduleAddSchedule}}</a>
    </div>
  </div>
</template>

<script>
  import ctrl from "./js/AddSchedule";
  export default ctrl;

</script>
