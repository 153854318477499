import filter from "@/service/filter/filter.sub";
import SelectDrop from "@/components/Input/SelectDrop";
import store from "@/store";
import moment from "moment";

export default {
  name: 'AddSchedule',
  data() {
    return {
      CountryList: store.state.Public.Country,
      page: 1,
      arealist: [],
      FirstTmpselect: "",
      infodata: {
        uid: "",
        name: "",
        country: [],
        startdate: "",
        totalday: 0,
        enddate: "",
        FirstArea: "",
        FirstLocation: []
      }
    };
  },
  methods: {
    next() {
      this.page++;
    },
    pre() {
      this.page--;
    },
    OpenClick() {
      this.CountryList = store.state.Public.Country;
      return this.CountryList;
    },
    deleteitem(index) {
      return this.infodata.country.splice(index, 1);
    },
    changeFirstday(data) {
      const opt = data.value.split("-");
      store.dispatch("Public/SearchPostCode", {
        Country: opt[0],
        City: opt[1]
      }).then((data) => {
        this.arealist = data.data.result;
      });
    },
    submit() {
      const data = {
        method: this.type,
        data: this.infodata
      }
      store.dispatch("schedule/createSchedule", data).then((data) => {
        if (data.data.state) {
          $.magnificPopup.close();
        }
      })
    }
  },
  computed: {
    countrylist() {
      const text = this.infodata.country.map((item) => {
        const exp = item.split("-");
        const sub = filter.filter(this.CountryList, {
          value: exp[0]
        })[0];
        let result = [sub.group];
        if (exp[1] == "all") {
          result[1] = this.langpublic.all;
        } else {
          const s = filter.filter(sub.sub, {
            value: exp[1]
          })[0];
          result[1] = s.name;
        }
        return {
          name: result.join("-"),
          value: item
        };
      })
      return text;
    },

    FirstTmepFilter() {
      let result = this.arealist;
      if (this.FirstTmpselect.name) {
        result = filter.filter(this.arealist, {
          name: this.FirstTmpselect.name
        });

      }
      return result;
    },

    lang: {
      get() {
        return store.state.Public.lang.addSchedule;
      }
    },

    langresign: {
      get() {
        return store.state.Public.lang.resign;
      }
    },
    
    langpublic: {
      get() {
        return store.state.Public.lang.public;
      }
    }
  },
  components: {
    SelectDrop
  },
  watch: {
    "infodata.startdate"(value) {
      const date = this.$moment(value);
      if (this.infodata.totalday) {
        this.infodata.enddate = date.add(this.infodata.totalday - 1, "days");
      } else if (this.infodata.enddate) {
        this.infodata.totalday = (-date.diff(this.infodata.enddate, "days")) + 1;
      }
    },
    "infodata.totalday"(value) {
      if (this.infodata.startdate) {
        parseInt()
        const date = moment(this.infodata.startdate);
        this.infodata.enddate = date.add(parseInt(value) - 1, "days");
      } else if (this.infodata.enddate) {
        const date = moment(this.infodata.enddate);
        this.infodata.startdate = date.add(-parseInt(value) + 1, "days");
      }
    }
    // "infodata.enddate": (value) => {
    //   console.log(value);
    //   const date = moment(value);
    //   if (this.infodata.totalday) {
    //     this.infodata.startdate = date.add(-(this.infodata.totalday - 1), "days");
    //   } else if (this.infodata.startdate) {
    //     this.infodata.totalday = (date.diff(this.infodata.startdate, "days"))+1;
    //   }
    // },
  },
  props: {
    type: String,
    callback: Function,
  },
};
