<template>
  <div class="d-flex justify-content-center p-0 px-md-1">

    <div class="w-100 my-1" style="max-width:26rem">

      

      <!-- :class='{"min-vh-100":boxMinus}' -->
      <div class="h-100 card shadow rounded m-0">

        <!-- title 我的行程 -->
        <div class="card-header bg-dagee-blue py-1 px-4">
          <div class="d-flex align-items-center">
            <b class="font-up-2 flex-fill text-dark">{{lang.title}}</b>

            <a class="cur-print" href="#add-dialog"
              v-magnificPopup="{open:AddSchedule, close:closefunc}" v-if="member.state">
              <font-awesome-icon class="text-dark" :icon="['fas', 'plus']" />
            </a>

          </div>
        </div>

        <!-- nav 自建 / 分享 -->
        <nav class="nav nav-justified pms-tabs border-bottom">
          <a href="#" class="p-1 nav-item nav-link" :class='{active:ScheduleMethod=="MySchedule"}'
            @click.prevent='ChangeMedod("MySchedule")'>{{lang.Tabs.MySchedule}}</a>

          <a href="#" class="p-1 nav-item nav-link"
            :class='{active:ScheduleMethod=="ShareSchedule"}'
            @click.prevent='ChangeMedod("ShareSchedule")'>{{lang.Tabs.ShareSchedule}}</a>
        </nav>

        <div class="card-body h-100 p-0 m-0 overflow-auto">
          <!-- schedule 行程清單 -->
          <div class="w-100 p-2">
            <div class="card mb-2 shadow-sm" v-for="(item, index) in listdata" :key="index">
              <!-- 圖片 -->
              <div class="card-img overflow-hidden" style="min-height: 120px">
                <!-- <b-img-lazy v-bind='mainProps' :src="item.image" alt="Image 1">
                </b-img-lazy> -->
              </div>
              <!-- 內容 -->
              <div class="card-img-overlay p-0" :class="item.textcolor">

                <div class="w-100 h-100">
                  <div class="w-100 p-2">
                    <div class="w-100">
                      <!-- title update 標題/更新 -->
                      <div class="d-flex align-items-start justify-content-center"
                        style="min-height:54px">
                        <router-link class="font-up-2 flex-fill flex-wrap mr-3"
                          :class="item.textcolor" tag="b"
                          :to='{name:"Edit", params: {Schedule: item.uid}}'>
                          {{item.Title ? item.Title : "尚未輸入"}}
                        </router-link>

                        <div class="cur-print text-danger" v-if='item.Edit'
                          @click='EditScheduleInfo(item)'>
                          <font-awesome-icon :icon="['fas', 'edit']" size="lg" />
                        </div>
                      </div>

                      <div class="d-flex align-items-cneter">
                        <div class="flex-fill">
                          <span>
                            {{item.StartDate | moment("YYYY/MM/DD")}}
                            ({{item.StartDate | moment("dd")}})
                          </span>
                          ~
                          <span>
                            {{item.EndDate | moment("YYYY/MM/DD")}}
                            ({{item.EndDate | moment("dd")}})
                          </span>
                        </div>
                        <div class="text-center" style="width: 60px">
                          <span>共 {{item.EndDate | moment("diff", item.StartDate, "days") + 1}}
                            天</span>
                        </div>
                      </div>
                    </div>
                    <!-- schedule 地方 -->
                    <!-- <div class="flex-fill align-items-end">
                      <div class="w-100 clearfix">
                        <div class="bg-info float-left mr-1 mt-1"
                          v-for='(ic, index) in item.country' :key="index">
                          {{ic.country}} - {{ic.city}}
                        </div>
                      </div>
                      <p class='mt-2 overflow-auto p-0 m-0 clearfix' style='max-height:100px;'
                        v-html='item.contextinfo'>
                      </p>
                    </div> -->


                    <div class="d-flex align-items-center justify-content-end">
                      <!-- <span class="text-danger">
                        {{item.price | currency()}}
                        <small class="text-dark">{{lang.peole}}</small>
                      </span> -->

                      <span>
                        {{lang.DesignMember}} : <span
                          class="letter-s-1">{{item.belongMemeber}}</span>
                      </span>
                    </div>


                  </div>

                  <!-- <div class="h-100 d-flex">
                    <router-link class="d-flex align-items-center caret-enter" tab="a"
                      :to='{name:"Edit", params: {Schedule: item.uid}}'>
                      <font-awesome-icon class="font-up-10" :icon="['fas', 'caret-right']" />
                    </router-link>
                  </div> -->

                </div>
              </div>
            </div>

            <div class="card cur-print" style="border:1px dashed #555" href="#add-dialog"
              v-magnificPopup="{open:AddSchedule, close:closefunc}" v-if="member.state">
              <div class="d-flex align-items-center align-self-center" style="height: 150px">
                <font-awesome-icon :icon="['fas', 'plus']" />
              </div>
            </div>

            <router-link class="card cur-print" style="border:2px dashed #555" v-if='!member.state'
              :to='{name:"login"}'>
              <div class="d-flex align-items-center align-self-center" style="height: 150px">
                <span>{{lang.text.placeLogin}}</span>
              </div>
            </router-link>

            <!-- <router-link class="d-flex align-items-center cur-print" :to='{name:"login"}' tag="a"
              v-if="!member.state">
              <font-awesome-icon :icon="['fas', 'user']" size='lg' />
            </router-link> -->

          </div>
        </div>
      </div>
    </div>

    <div class="flex-fill d-md-block d-none">

      尚未決定

    </div>

    <div id="add-dialog" class="zoom-anim-dialog dialog mfp-hide">
      <div class="small-dialog-header">
        <h3>{{lang[addScheduletype]}}</h3>
      </div>
      <AddSchedule :type="addScheduletype" :callback="callfunc" v-if='openeditbox' />
    </div>

    <EditSchedulePage :Secheduleuid="Editdata.data.uid" :show.sync="Editdata.show" :reback="reback"
      :deleteMySchedule="deleteMySchedule" />
  </div>
</template>

<script>
  import controler from "./schedule"
  export default controler

</script>

<style lang='scss'>
  @import '../../assets/scss/variables';

  .caret-enter {
    cursor: pointer;
    font-size: 3rem;
    background: rgba(#000, 0.5);
    transition: 0.5s background;
    padding: 0rem 0.5rem;

    &:hover {
      font-size: 4rem;
      background: rgba(#555, 1);
    }
  }

</style>
